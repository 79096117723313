@import url('https://drive.google.com/uc?id=190IXt0-LyOQr2v-BXgGRv79_OFrepSGL');

@font-face {
  font-family: 'Averta CY';
  src: url('AvertaCY.woff') format('woff');
}

:root {
  --font-family: 'Averta CY', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}
