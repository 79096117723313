@font-face {
  font-family: 'Averta CY';
  src: url('AvertaCY.woff') format('woff');
}

.contact-form {
  font-family: 'Averta CY' , 'sans-serif';
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
}

.contact-form > h1 {
  font-size: 60px;
  font-weight: bold;
  background: linear-gradient(to right, #AE67FA 1.84%, #F49867 102.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-form > input,
.contact-form > textarea {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 2px solid #031b34;
  background: #00131c;
  font-size: 16px;
  color: #fff;
  outline: none;
}

.contact-form > input:focus,
.contact-form > textarea:focus {
  border: 1px solid transparent;
  border-image: linear-gradient(to right, #AE67FA, #F49867);
  border-image-slice: 1;
}

.contact-form > textarea {
  height: 150px;
  max-width: 400px;
  min-height: 100px;
}

.contact-form > label {
  padding-bottom: 10px;
  font-weight: bold;
  background: linear-gradient(to right, #AE67FA, #F49867);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-form > button {
  padding: 20px;
  border: none;
  background-color: #FF4820;
  font-weight: 500;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.contact-form > button:hover {
  background-image: linear-gradient(to right, #AE67FA, #F49867);
}
